<template>
    <div class="box">
        <div class="box_banner">
            <div class="box_banner_mid">
                <p>{{activeObj.title}} 
                    <span class="banner_mid_color">solution</span>
                    </p>
                <img src="../assets/case_banner.png" alt="">
            </div>
        </div>
        <div class="box_introduce">
            <div class="cont">
                <!-- 方案介绍 -->
                <h3>{{$t('case.introduceTitle')}}</h3>
                <div class="box_introduce_card">
                    <img src="../assets/case_icon.png" class="introduce_card_img" alt="">
                    <div>
                        <p class="box_introduce_title">{{activeObj.title}}</p>
                        <p class="box_introduce_value">{{activeObj.introduce}}</p>
                        <div class="box_introduce_btn">{{$t('case.introduceBtn')}}</div>
                        <p class="box_introduce_value">{{activeObj.solve}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="cont">
            <h3>方案优势</h3>
            <div class="box_advantage" v-for="(item,i) in activeObj.advantageList" :key="i">
                <div class="box_advantage_color"></div>
                <div class="box_advantage_cont">
                    <img :src="item.url" alt="">
                    <div>
                        {{item.title}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeObj:{}
        }
    },
    created() {
    //    let index= sessionStorage.getItem("caseid")
    //    if(index>=0){
    //        this.activeObj=this.list[index]
    //    }else{
    //        this.activeObj=this.list[0]
    //    }
    var index=this.$route.query.id
    this.activeObj=this.list[index]
    },
    computed:{
        // activeObj(){
        //     console.log("1231231")
        //     let index= this.$store.state.ids
        //     if(index>=0){
        //         return this.list[index]
        //     }else{
        //         return this.list[0]
        //     }
        // },
        list(){
            return [
                {
                    title:this.$t('header.caseList')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader'),
                    solve:this.$t('case.introduceSolve'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList')
                        },
                        {
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList2')
                        },
                         {
                            url:require("../assets/saas_icon7.png"),
                            title:this.$t('case.advantageList3')
                        }
                    ]
                },{
                    title:this.$t('header.caseList2')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader2'),
                    solve:this.$t('case.introduceSolve2'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList4')
                        },
                        {
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList5')
                        },
                         {
                            url:require("../assets/saas_icon7.png"),
                            title:this.$t('case.advantageList6')
                        }
                    ]
                },{
                    title:this.$t('header.caseList3')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader3'),
                    solve:this.$t('case.introduceSolve3'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList7')
                        },
                        {
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList8')
                        },
                         {
                            url:require("../assets/saas_icon7.png"),
                            title:this.$t('case.advantageList9')
                        }
                    ]
                },{
                    title:this.$t('header.caseList4')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader4'),
                    solve:this.$t('case.introduceSolve4'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList10')
                        },
                        {
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList11')
                        },
                         {
                            url:require("../assets/saas_icon7.png"),
                            title:this.$t('case.advantageList12')
                        }
                    ]
                },{
                    title:this.$t('header.caseList5')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader5'),
                    solve:this.$t('case.introduceSolve5'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList13')
                        },
                        {
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList14')
                        }
                    ]
                },{
                    title:this.$t('header.caseList6')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader6'),
                    solve:this.$t('case.introduceSolve6'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList15')
                        },
                        {
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList16')
                        },
                         {
                            url:require("../assets/saas_icon7.png"),
                            title:this.$t('case.advantageList17')
                        }
                    ]
                },{
                    title:this.$t('header.caseList7')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader7'),
                    solve:this.$t('case.introduceSolve7'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList18')
                        },
                        {
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList19')
                        },
                         {
                            url:require("../assets/saas_icon7.png"),
                            title:this.$t('case.advantageList20')
                        }
                    ]
                },{
                    title:this.$t('header.caseList8')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader8'),
                    solve:this.$t('case.introduceSolve8'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList21')
                        },
                        {
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList22')
                        },
                         {
                            url:require("../assets/saas_icon7.png"),
                            title:this.$t('case.advantageList23')
                        }
                    ]
                },{
                    title:this.$t('header.caseList9')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader9'),
                    solve:this.$t('case.introduceSolve9'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList24')
                        },
                       
                    ]
                },{
                    title:this.$t('header.caseList10')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader10'),
                    solve:this.$t('case.introduceSolve10'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList25')
                        },{
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList26')
                        },
                       
                    ]
                },{
                    title:this.$t('header.caseList11')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader11'),
                    solve:this.$t('case.introduceSolve11'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList27')
                        }
                       
                    ]
                },{
                    title:this.$t('header.caseList12')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader12'),
                    solve:this.$t('case.introduceSolve12'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList28')
                        }
                    ]
                },{
                    title:this.$t('header.caseList13')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader13'),
                    solve:this.$t('case.introduceSolve13'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList29')
                        }
                    ]
                },{
                    title:this.$t('header.caseList14')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader14'),
                    solve:this.$t('case.introduceSolve14'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList30')
                        }
                    ]
                },{
                    title:this.$t('header.caseList15')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader15'),
                    solve:this.$t('case.introduceSolve15'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList31')
                        }
                    ]
                },{
                    title:this.$t('header.caseList16')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader16'),
                    solve:this.$t('case.introduceSolve16'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList32')
                        }
                    ]
                },{
                    title:this.$t('header.caseList17')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader17'),
                    solve:this.$t('case.introduceSolve17'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList33')
                        }
                    ]
                },{
                    title:this.$t('header.caseList18')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader18'),
                    solve:this.$t('case.introduceSolve18'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList34')
                        }
                    ]
                },{
                    title:this.$t('header.caseList19')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader19'),
                    solve:this.$t('case.introduceSolve19'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList35')
                        },{
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList36')
                        }
                    ]
                },{
                    title:this.$t('header.caseList20')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader20'),
                    solve:this.$t('case.introduceSolve20'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList37')
                        }
                    ]
                },{
                    title:this.$t('header.caseList21')+this.$t('header.case'),
                    introduce:this.$t('case.introduceHeader21'),
                    solve:this.$t('case.introduceSolve21'),
                    advantageList:[
                        {
                            url:require("../assets/saas_icon5.png"),
                            title:this.$t('case.advantageList38')
                        },{
                            url:require("../assets/saas_icon6.png"),
                            title:this.$t('case.advantageList39')
                        }
                    ]
                }
                
            ]
        }
    }
}
</script>
<style scoped>
.box_banner{
    width: 100%;
    height: 437px;
}
.box_introduce{
    background-color: #F3F5FA;
    width: 100%;
    padding-bottom: 68px;
}
.cont{
    width: 1200px;
    margin: auto;
    padding-bottom: 50px;
}
h3{
    font-weight: 600;
    font-size: 36px;
    color: #1D2023;
    padding: 68px 0 40px 0;
}
.box_introduce_card{
    background-color: #FFFFFF;
    padding: 55px 40px;
    display: flex;
    align-items: center;
}
.introduce_card_img{
    width: 276px;
    height: 228px;
    margin-right: 42px;
}
.box_introduce_title{
    font-weight: 600;
    font-size: 18px;
    color: #1D2023;
    margin-bottom: 16px;
}
.box_introduce_value{
    color: #3F454B;
    font-size: 14px;
}
.box_introduce_btn{
    width: 84px;
    height: 32px;
    background: linear-gradient(270deg, #41C0CB 0%, #62D6B6 100%);
    border-radius: 16px;
    text-align: center;
    line-height: 32px;
    color: #FFFFFF;
    font-size: 14px;
    margin: 28px 0 16px 0;
}
.box_advantage{
    height: 133px;
    background: #FFFFFF;
    box-shadow: 0px 15px 16px 0px rgba(194, 202, 219, 0.38);
    border-radius: 4px;
    margin-bottom: 40px;
}
.box_advantage_color{
    background-color: #6D98EE;
    height: 3px;
    border-radius: 4px 4px 0 0 ;
}
.box_advantage_cont{
    display: flex;
    align-items: center;
    color: #1D2023;
    font-size: 18px;
    font-weight: 400;
    height: 130px;
}
.box_advantage_cont img{
    width: 50px;
    height: 50px;
    margin:0 40px ;
}
.box_banner{
    width: 100%;
    height: 343px;
    background-image: url("../assets/case_background.png");
    background-size: 100% 343px;
}
.box_banner_mid{
    width: 1200px;
    margin: auto;
    color: #FFFFFF;
    font-size: 46px;
    font-weight: 600;
    position: relative;
    padding-top: 180px;
    box-sizing: border-box;
}
.box_banner_mid img{
    position: absolute;
    right: 8px;
    top: 180px;
    width: 443px;
    height: 185px;
}
.banner_mid_color{
    color: #78A0FF;
}
</style>